<template>
  <div class="TransferType TransferType--withdrawal" v-if="type === 'withdrawal'">
    <i class="bi bi-arrow-bar-left" />
    Withdrawal
  </div>

  <div class="TransferType TransferType--deposit" v-if="type === 'deposit'">
    <i class="bi bi-arrow-bar-right" />
    Deposit
  </div>
</template>

<script>
  export default {
    props: {
      type: {
        type: String,
        default: null
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import '@/sass/styles.vars.all.sass'

  .TransferType
    border-radius: .5em
    padding: .25em 1em
    display: inline-block
    text-transform: uppercase
    font-size: .85rem

    i
      margin-right: .5em

  .TransferType--withdrawal
    background: tint-color($tertiary, 86)
    color: $tertiary

  .TransferType--deposit
    background: tint-color($info, 90)
    color: $info
</style>
