<template>
  <main class="TransfersView">
    <div class="container">
      <header class="mb-3 d-flex">
        <h1 class="m-0">Transfers</h1>

        <button class="btn btn-sm btn-primary ms-auto" @click="openForm()" v-if="isAuthAdmin && isAuthImpersonate">
          Add Transfer
        </button>
      </header>

      <AppLoading v-if="loading" :spaced="false" />

      <AppEmptyList v-if="!loading && !transfers.length" />

      <section class="TransfersView__list mb-5" v-if="!loading && transfers.length">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table class="TransfersView__table table">
                <colgroup>
                  <col>
                  <col>
                  <col>
                  <col style="width: 50px" v-if="authenticated.admin">
                </colgroup>
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>Date</th>
                    <th class="text-end">Amount</th>
                    <th v-if="authenticated.admin" />
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="transfer in transfers" :key="transfer.id">
                    <td>
                      <TransferType :type="transfer.type" />
                    </td>
                    <td>
                      <time>{{ formatDate(transfer.date) }}</time>
                    </td>
                    <th class="text-end">
                      <strong class="text-primary">
                        ${{ formatDecimal(transfer.amount) }}
                      </strong>
                    </th>
                    <td class="text-end" v-if="authenticated.admin">
                      <div class="btn btn-link btn-sm" @click.stop="openForm(transfer)">
                        <i class="bi bi-gear" />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
  import AppLoading from '@/components/AppLoading'
  import AppEmptyList from '@/components/AppEmptyList'
  import TransferType from '@/components/TransferType'
  import ModalTransferForm from '@/components/ModalTransferForm'

  import transferService from '@/services/transfers.service'

  import useModal from '@/plugins/modal/useModal'
  import { formatDate } from '@/utils/date'
  import { formatDecimal } from '@/utils/number'
  import { mapState, mapGetters } from 'vuex'

  export default {
    components: {
      AppEmptyList,
      AppLoading,
      TransferType
    },

    setup() {
      return {
        modal$: useModal()
      }
    },

    data() {
      return {
        loading: true,
        transfers: null,
        exchange: null
      }
    },

    computed: {
      ...mapState('auth', [
        'authenticated',
        'impersonated'
      ]),

      ...mapGetters('auth', [
        'isAuthAdmin',
        'isAuthAgent',
        'isAuthImpersonate'
      ])
    },

    mounted() {
      this.load()
    },

    methods: {
      formatDate,
      formatDecimal,

      async load() {
        this.loading = true
        this.transfers = await transferService.fetch()
        this.loading = false
      },

      openForm(transfer) {
        this.modal$
          .open(ModalTransferForm, {
            closeOnBackdrop: false,
            scrollable: true,
            props: {
              transfer
            }
          })
          .onClose((response) => {
            if ([ 'DELETED', 'SAVED' ].includes(response)) {
              this.load()
            }
          })
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import '@/sass/styles.vars.all.sass'

  .TransfersView__table
    margin-bottom: 150px

    tr
      vertical-align: middle

    th, td
      white-space: nowrap
</style>
