<template>
  <div class="modal-header">
    <h5 class="modal-title">
      <span v-if="transfer.id">Edit a Transfer</span>
      <span v-if="!transfer.id">Add a Transfer</span>
    </h5>

    <button type="button" class="btn-close" @click="modal.close()" />
  </div>

  <div class="modal-body">
    <div class="alert alert-danger" v-if="formError">
      {{ formError }}
    </div>

    <form novalidate class="form">
      <div class="mb-3 form-required">
        <FormSelect label="Type" :validator="v$.form.type" :options="options" />
      </div>

      <div class="mb-3 form-required">
        <FormInput label="Amount" type="number" step="any" :validator="v$.form.amount" />
      </div>

      <div class="col-md-6">
        <div class="mb-3 form-required">
          <FormInput label="Date" type="date" :validator="v$.form.date" />
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer d-flex">
    <button type="button" class="btn btn-danger me-auto" @click="remove(transfer)" v-if="transfer.id" :disabled="v$.$dirty">
      Remove
    </button>

    <button type="button" class="btn btn-outline-primary" @click="modal.close()">
      Cancel
    </button>

    <button type="button" class="btn btn-primary" @click="save" :disabled="v$.$invalid">
      Save
    </button>
  </div>
</template>

<script>
  import ModalConfirm from '@/components/ModalConfirm'
  import FormInput from '@/components/base/FormInput'
  import FormSelect from '@/components/base/FormSelect'

  import transferService from '@/services/transfers.service'

  import useVuelidate from '@vuelidate/core'
  import useModal from '@/plugins/modal/useModal'
  import { required, decimal, minValue } from '@vuelidate/validators'
  import { formatDate } from '@/utils/date'
  import { omit } from 'lodash'

  export default {
    components: {
      FormInput,
      FormSelect
    },

    props: {
      transfer: {
        type: Object,
        default: () => ({})
      },
      modal: {
        type: Object,
        default: () => ({})
      }
    },

    setup() {
      return {
        v$: useVuelidate(),
        modal$: useModal()
      }
    },

    data() {
      return {
        form: {
          type: 'withdrawal',
          amount: 1,
          date: formatDate(new Date(), 'yyyy-MM-dd')
        },
        formError: '',
        options: { withdrawal: 'Withdrawal', deposit: 'Deposit' }
      }
    },

    watch: {
      transfer: {
        immediate: true,
        handler(transfer) {
          this.form = { ...this.form, ...transfer }
          this.form.date = formatDate(this.form.date, 'yyyy-MM-dd')
        }
      }
    },

    validations() {
      return {
        form: {
          type: { required },
          amount: { required, decimal, minValue: minValue(0.01) },
          date: { required }
        }
      }
    },

    methods: {
      remove() {
        this.formError = ''

        this.modal$
          .open(ModalConfirm, {
            type: 'danger'
          })
          .onClose(async (confirm) => {
            if (confirm) {
              try {
                await transferService.delete(this.form.id)
                this.modal.close('DELETED')
              } catch (e) {
                this.formError = 'Something went wrong on the server when deleting: ' + e.data.message
              }
            }
          })
      },

      async save() {
        this.formError = ''

        let payload = this.form

        if (payload.id && !this.apiInfoEdited) {
          payload = omit(this.form, [ 'api', 'secret' ])
        }

        try {
          await transferService.save(payload)
          this.modal.close('SAVED')
        } catch (e) {
          this.formError = 'Something went wrong on the server: ' + e.data.message
        }
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import '@/sass/styles.vars.all.sass'

  fieldset
    border: 1px solid $gray-300
    padding: $spacer $spacer 0
    margin-bottom: $spacer
    background: $gray-100
</style>

